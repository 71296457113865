<template lang="">
  <div>
    <IAmOverlay :loading="loadingDetail || loadingSignModal">
      <ValidationObserver ref="refFormObserver">
        <Stack
          v-if="incorrectInvoiceData"
          id="incorrect-invoice-detail"
          class="gap-2"
        >
          <IncorrectInvoiceInfoCard
            :incorrectInvoiceData.sync="incorrectInvoiceData"
            :isEditing="isEditing"
          />
          <BRow>
            <BCol
              cols="12"
              :md="collapseSelectedIncorrectInvoices ? 12 : 4"
              :class="!collapseSelectedIncorrectInvoices ? 'pr-md-50' : ''"
            >
              <IncorrectInvoiceSelectCard
                :incorrectInvoiceData.sync="incorrectInvoiceData"
                :collapseSelectedIncorrectInvoices.sync="collapseSelectedIncorrectInvoices"
                :isEditing="isEditing"
              />
            </BCol>
            <BCol
              cols="12"
              :md="collapseSelectedIncorrectInvoices ? 12 : 8"
              :class="!collapseSelectedIncorrectInvoices ? 'px-md-50' : 'mt-50'"
            >
              <IncorrectInvoiceDetailCard
                :incorrectInvoiceData.sync="incorrectInvoiceData"
                :resetIncorrectInvoices="resetIncorrectInvoices"
                :isInvalidIncorrectInvoiceItems="isInvalidIncorrectInvoiceItems"
                :isEditing="isEditing"
              />

              <!-- SECTION Buttons -->
              <BRow
                align-v="center"
                class="button-actions"
              >
                <!-- ANCHOR EDIT -->
                <BCol cols="4">
                  <BButton
                    v-if="!isEditing"
                    pill
                    variant="info"
                    class="w-100"
                    :disabled="!BUTTON_ACTION_PERMISSIONS_INCORRECT_INVOICE?.[incorrectInvoiceData.status]?.[incorrectInvoiceData.statusOfTaxAuthority]?.EDIT"
                    @click="onEditHandle(true)"
                  >
                    {{ $t('invoice.btn.edit') }}
                  </BButton>
                  <div
                    v-else
                    class="d-flex-center gap-3"
                  >
                    <BButton
                      pill
                      variant="info"
                      class="flex-1"
                      :disabled="!BUTTON_ACTION_PERMISSIONS_INCORRECT_INVOICE?.[incorrectInvoiceData.status]?.[incorrectInvoiceData.statusOfTaxAuthority]?.EDIT"
                      @click="onSaveEditHandle"
                    >
                      {{ $t('invoice.btn.edit') }}
                    </BButton>
                    <BButton
                      v-b-tooltip.top.hover.window="$t('invoice.btn.cancelEdit')"
                      pill
                      variant="outline-danger"
                      class="p-50"
                      :disabled="!BUTTON_ACTION_PERMISSIONS_INCORRECT_INVOICE?.[incorrectInvoiceData.status]?.[incorrectInvoiceData.statusOfTaxAuthority]?.EDIT"
                      @click="onEditHandle(false)"
                    >
                      <IAmIcon
                        icon="closeOutline"
                        size="18"
                      />
                    </BButton>
                  </div>
                </BCol>

                <!-- ANCHOR SIGN -->
                <BCol cols="4">
                  <BButton
                    pill
                    class="w-100"
                    variant="warning"
                    :disabled="!BUTTON_ACTION_PERMISSIONS_INCORRECT_INVOICE?.[incorrectInvoiceData.status]?.[incorrectInvoiceData.statusOfTaxAuthority]?.SIGN"
                    @click="onSignHandle"
                  >
                    {{ $t('invoice.incorrectInvoices.btn.sign') }}
                  </BButton>
                </BCol>

                <!-- ANCHOR sendTaxAuthority -->
                <BCol cols="4">
                  <BButton
                    pill
                    :disabled="!BUTTON_ACTION_PERMISSIONS_INCORRECT_INVOICE?.[incorrectInvoiceData.status]?.[incorrectInvoiceData.statusOfTaxAuthority]?.SEND_TAX_AUTHORITY"
                    class="w-100"
                    variant="warning"
                    @click="onSendTaxAuthorityHandle"
                  >
                    {{ $t('invoice.incorrectInvoices.btn.sendTaxAuthority') }}
                  </BButton>
                </BCol>

                <!-- ANCHOR sendEmail -->
                <BCol cols="4">
                  <BButton
                    pill
                    class="w-100"
                    :disabled="!BUTTON_ACTION_PERMISSIONS_INCORRECT_INVOICE?.[incorrectInvoiceData.status]?.[incorrectInvoiceData.statusOfTaxAuthority]?.SEND_EMAIL"
                    variant="info"
                    @click="onSendEmailHandle"
                  >
                    {{ $t('invoice.incorrectInvoices.btn.sendEmail') }}
                  </BButton>
                </BCol>

                <!-- ANCHOR preview -->
                <BCol cols="4">
                  <BButton
                    pill
                    class="w-100"
                    variant="info"
                    :to="{ name: 'apps-incorrectInvoices-preview', params: { id: incorrectInvoiceData?.id ?? idRoute } }"
                    :disabled="!BUTTON_ACTION_PERMISSIONS_INCORRECT_INVOICE?.[incorrectInvoiceData.status]?.[incorrectInvoiceData.statusOfTaxAuthority]?.VIEW_PDF"
                    target="_blank"
                  >
                    {{ $t('invoice.incorrectInvoices.btn.preview') }}
                  </BButton>
                </BCol>

                <!-- ANCHOR EXIT -->
                <BCol cols="4">
                  <BButton
                    pill
                    class="w-100"
                    variant="outline-secondary"
                    @click="onExitHandle"
                  >
                    {{ $t('invoice.btn.exit') }}
                  </BButton>
                </BCol>
              </BRow>
              <!-- !SECTION -->
            </BCol>
          </BRow>
        </Stack>
      </ValidationObserver>
    </IAmOverlay>

    <IncorrectInvoiceSignModal
      :incorrectInvoices="[incorrectInvoiceData]"
      :companyId="incorrectInvoiceData?.company?.id ?? null"
      @refetch="fetchIncorrectInvoiceById"
    />

    <IncorrectInvoiceSendEmailsModal :incorrectInvoices="incorrectInvoicesSendEmail || []" />
  </div>
</template>
<script>
import { ValidationObserver } from 'vee-validate'
import {
  computed,
  nextTick, onUnmounted, ref, watch,
} from '@vue/composition-api'
import { cloneDeep } from 'lodash-es'
import { BButton, BCol, BRow } from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

import formValidation from '@/@core/comp-functions/forms/form-validation'
import { useRouter } from '@/@core/utils/utils'
import store from '@/store'
import { convertISODateTime } from '@/@core/utils/filter'
import { numberToFixed } from '@/@core/utils/numberUtils'
import { BUTTON_ACTION_PERMISSIONS_INCORRECT_INVOICE } from '@/constants/invoice'

import IncorrectInvoiceDetailCard from './components/IncorrectInvoiceDetailCard.vue'
import IncorrectInvoiceInfoCard from './components/IncorrectInvoiceInfoCard.vue'
import IncorrectInvoiceSelectCard from './components/IncorrectInvoiceSelectCard.vue'
import useIncorrectInvoiceHandle, { useIncorrectInvoiceDetailHandle, useIncorrectInvoiceSignModalHandle } from './useIncorrectInvoiceHandle'
import invoiceStoreModule from '../invoiceStoreModule'
import incorrectInvoiceStoreModule from './incorrectInvoiceStoreModule'

export default {
  components: {
    IncorrectInvoiceDetailCard,
    IncorrectInvoiceSelectCard,
    IncorrectInvoiceInfoCard,
    ValidationObserver,
    BCol,
    BRow,
    BButton,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    IncorrectInvoiceSignModal: () => import('./components/IncorrectInvoiceSignModal.vue'),
    IncorrectInvoiceSendEmailsModal: () => import('./components/IncorrectInvoiceSendEmailsModal.vue'),
  },
  setup(_, { root }) {
    const { refFormObserver } = formValidation()

    const { router, route } = useRouter()
    const idRoute = computed(() => route.value.params.id)
    const { getIncorrectInvoiceById, loadingDetail, updateIncorrectInvoice } = useIncorrectInvoiceDetailHandle()
    const { INVOICE_STORE, INCORRECT_INVOICE_STORE } = useIncorrectInvoiceHandle()
    const { releaseIncorrectInvoicesHandle, loadingSignModal } = useIncorrectInvoiceSignModalHandle()
    // Register module
    if (!store.hasModule(INVOICE_STORE)) store.registerModule(INVOICE_STORE, invoiceStoreModule)
    if (!store.hasModule(INCORRECT_INVOICE_STORE)) store.registerModule(INCORRECT_INVOICE_STORE, incorrectInvoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_STORE)) store.unregisterModule(INVOICE_STORE)
      if (store.hasModule(INCORRECT_INVOICE_STORE)) store.unregisterModule(INCORRECT_INVOICE_STORE)
    })

    const incorrectInvoiceData = ref()
    const resetIncorrectInvoices = ref({ value: uuidv4() }) // biến để reset selected invoices trong detail card
    const originIncorrectInvoiceData = ref()
    const selectedInvoices = computed(() => store.getters[`${INCORRECT_INVOICE_STORE}/getSelectedInvoices`])
    const isInvalidIncorrectInvoiceItems = ref(false) // check đã chọn ds hoá đơn chưa

    function initIncorrectInvoice() {
      incorrectInvoiceData.value = cloneDeep(originIncorrectInvoiceData.value)
      resetIncorrectInvoices.value.value = uuidv4()
    }

    const isEditing = ref(false)
    async function fetchIncorrectInvoiceById(id = idRoute.value) {
      const res = await getIncorrectInvoiceById(id)
      originIncorrectInvoiceData.value = cloneDeep({
        ...res,
        dateOfIssuance: convertISODateTime(res.dateOfIssuance).dateFilter,
        dateOfTaxReviewNotice: res.dateOfTaxReviewNotice ? convertISODateTime(res.dateOfTaxReviewNotice).dateFilter : undefined,
      })
      nextTick(() => {
        initIncorrectInvoice()
      })
    }

    watch(selectedInvoices, () => {
      // Chưa chọn hoá đơn nào => lỗi => chọn hoá đơn => tắt báo lỗi
      if (selectedInvoices.value.length && isInvalidIncorrectInvoiceItems.value) {
        isInvalidIncorrectInvoiceItems.value = false
      }
    })

    watch(route, () => {
      if (route.value.hash.includes('#edit')) {
        isEditing.value = true
      }
      store.dispatch(`${INCORRECT_INVOICE_STORE}/clearSelectedInvoices`)
      fetchIncorrectInvoiceById(idRoute.value)
    }, { immediate: true })

    const collapseSelectedIncorrectInvoices = ref(false)

    function onExitHandle() {
      router.go(-1)
    }

    function onEditHandle(val) {
      isEditing.value = val
      if (!val) {
        initIncorrectInvoice()
      }
    }

    async function onSaveEditHandle() {
      // Chưa chọn hoá đơn nào => lỗi
      if (!selectedInvoices.value.length) {
        isInvalidIncorrectInvoiceItems.value = true
      }

      const isValid = await refFormObserver.value.validate()
      if (!isValid || isInvalidIncorrectInvoiceItems.value) return
      const payload = {
        ...incorrectInvoiceData.value,
        companyId: incorrectInvoiceData.value.company?.id,
        incorrectItems: selectedInvoices.value.map(inv => ({
          id: `${inv?.externalId ?? inv?.id}`,
          typeOfInvoiceApplication: inv?.typeOfInvoiceApplication || '',
          taxAuthorityCode: inv?.taxAuthorityCode || '',
          templateNumberAndSymbol: inv?.templateNumberAndSymbol || '',
          invoiceNumber: inv?.invoiceNumber || '',
          invoiceDate: `${inv?.invoiceDate}T09:00:00Z` || '',
          action: inv?.action || '',
          reason: inv?.reason || '',
          amount: numberToFixed(inv?.amount) || '',
          isInternal: inv?.isInternal || '',
          incorrectInvoiceNotificationId: inv?.incorrectInvoiceNotificationId || undefined,
          invoiceEmail: inv?.invoiceEmail || undefined,
          invoiceBuyerName: inv?.invoiceBuyerName || undefined,
          invoiceTotalAmount: numberToFixed(inv?.invoiceTotalAmount) || undefined,
          invoiceBuyerUnitName: inv?.invoiceBuyerUnitName || undefined,
        })),
        id: `${incorrectInvoiceData.value?.id}`,
        dateOfIssuance: incorrectInvoiceData.value?.dateOfIssuance ? `${incorrectInvoiceData.value?.dateOfIssuance}T09:00:00Z` : undefined,
        dateOfTaxReviewNotice: incorrectInvoiceData.value?.dateOfTaxReviewNotice ? `${incorrectInvoiceData.value?.dateOfTaxReviewNotice}T09:00:00Z` : undefined,
        invoiceIncorrectItems: undefined,
        company: undefined,
        createdAt: undefined,
        createdBy: undefined,
        updatedAt: undefined,
      }
      const res = await updateIncorrectInvoice(incorrectInvoiceData.value.id, payload)
      if (res?.id) {
        fetchIncorrectInvoiceById(res?.id)
        isEditing.value = false
      }
    }

    function onSignHandle() {
      root.$bvModal.show('modal-sign-incorrect-invoice')
    }

    function onSendTaxAuthorityHandle() {
      releaseIncorrectInvoicesHandle([incorrectInvoiceData.value], fetchIncorrectInvoiceById)
    }
    const incorrectInvoicesSendEmail = ref()
    function onSendEmailHandle() {
      incorrectInvoicesSendEmail.value = cloneDeep([incorrectInvoiceData.value])
      root.$bvModal.show('modal-send-incorrect-invoice-emails')
    }
    return {
      refFormObserver,
      incorrectInvoiceData,
      collapseSelectedIncorrectInvoices,
      loadingDetail,
      resetIncorrectInvoices,
      isEditing,
      onEditHandle,
      onSaveEditHandle,
      onExitHandle,
      onSignHandle,
      onSendTaxAuthorityHandle,
      onSendEmailHandle,
      isInvalidIncorrectInvoiceItems,
      fetchIncorrectInvoiceById,
      idRoute,
      loadingSignModal,
      BUTTON_ACTION_PERMISSIONS_INCORRECT_INVOICE,
      incorrectInvoicesSendEmail,
    }
  },
}
</script>
<style lang="scss" scoped>
#incorrect-invoice-detail ::v-deep {
  .form-group {
    margin-bottom: 0.25rem;
    label,
    legend {
      font-weight: 600;
      margin-bottom: 0;
      padding-bottom: 0;
      font-size: 1rem;
    }
  }
  .button-actions .col-4 {
    margin-bottom: 0.5rem;
  }
}
</style>
